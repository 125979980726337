import LR from "../../components/LeftRight.module.css";

import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect, type WSelectStyleProps, type WSelectValueProps } from "../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../components/forms/WSelectWrapper.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { adminHostPoolQueryAtom, type AdminHostPoolWithType } from "./hostPoolsQuery.ts";

export interface PoolSelectProps extends WSelectStyleProps, WSelectValueProps<AdminHostPoolWithType, string> {
    items: AdminHostPoolWithType[];
    location: string;
}

export function PoolSelectCustom({ items, ...props }: PoolSelectProps) {
    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select pool"
            items={items}
            getKey={(item) => item.uuid}
            getTextValue={(item) => item.name}
            searchItems
            renderSelectedValue={(item) => (
                <LeftRight
                    icon={<MaskIcon className="jp-hostpool-icon color-primary size-1rem" />}
                    title={item.name}
                    right={<>{item.hosts_assigned_count} host(s)</>}
                />
            )}
            {...props}
        >
            {(item) => (
                <LeftRight
                    icon={<MaskIcon className="jp-hostpool-icon color-primary size-1rem" />}
                    title={
                        <div>
                            <div>
                                {item.name}
                                {!!item.hosts_assigned_count && (
                                    <>
                                        {" "}
                                        - <span className="color-primary">{item.hosts_assigned_count} host(s)</span>
                                    </>
                                )}
                            </div>
                            <div className="font-size-small color-muted">{item.cpu_model}</div>
                        </div>
                    }
                    right={
                        <div className="font-size-small">
                            <div>
                                <span className="color-muted pr-2">CPU coef.:</span>
                                <span>{item.cpu_allocate_coefficient}</span>
                            </div>
                            <div>
                                <span className="color-muted pr-2">Mem coef.:</span>
                                <span>{item.mem_allocate_coefficient}</span>
                            </div>
                        </div>
                    }
                />
            )}
        </WSelect>
    );
}

export interface PoolSelectLoaderProps extends Omit<PoolSelectProps, "items"> {}

function PoolSelectLoader({ ...props }: PoolSelectLoaderProps) {
    const data = useSuspenseQueryAtom(adminHostPoolQueryAtom(props.location));
    const selected = [...data.values()].find((item) => item.uuid === props.valueKey);
    const pools = [...data.values()].filter((item) => item.storage_pool_uuid === selected?.storage_pool_uuid);
    return <PoolSelectCustom items={pools} defaultValueKey={props.valueKey} {...props} />;
}

export function PoolSelect(props: PoolSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <PoolSelectCustom {...props} {...renderProps} />}>
            <PoolSelectLoader {...props} />
        </WSelectWrapper>
    );
}
